<template>
  <div class="consumer-billing">
    <div class="vx-row mt-8">
      <div class="vx-col xl:w-2/3 mb-8 md:w-1/2 w-full lg-cus-6" v-if="!isReseller">
        <vx-card>
          <!-- Introduction plan section -->
          <div class="introduction-plan" v-if="billingInformation">
            <vx-card>
              <div class="card-title">
                <p>{{ billingInformation.Name }}</p>
                <h2>{{ billingInformation.IncludedVisitors | tableRecordNumberFormatter }} Visitors<sub>/MO</sub></h2>
              </div>
              <div class="card-body">
                <div class="bill-price">
                  <span><sup>$</sup>{{ billingInformation.Cost | tableRecordNumberFormatter }}<sub>/MO</sub></span>
                </div>
                <div class="bill-info-list">
                  <ul>
                    <li>Capture more at ${{ billingInformation.Rate | decimalsTwoDigitConversion | tableRecordNumberFormatter }} / visitor</li>
                    <li>Configure budget for restricting monthly spend</li>
                  </ul>
                </div>
                <button @click="getBillingInformationDetails()" class="bg-primary text-white">
                  {{ LabelConstant.buttonTitleViewDetails }}
                </button>
                <span>*Plan allows capturing up to {{ billingInformation.PlanCap | tableRecordNumberFormatter }} visitors / month</span>
              </div>
            </vx-card>
          </div>
          <!--end Introduction plan section -->
        </vx-card>
      </div>
      <div class="vx-col xl:w-1/3 mb-8 md:w-1/2 w-full lg-cus-6">
        <!-- Credit card information -->
        <vx-card class="credit-card">
          <div class="card-title">
            <h2 class="w-auto py-2">Credit Card Information</h2>
            <vs-button
              @click="updateCardActive = true"
              :title="LabelConstant.buttonLabelUpdateCreditCard"
              class="bg-actionbutton"
              type="filled"
              >{{ LabelConstant.buttonLabelUpdateCreditCard }}</vs-button
            >
          </div>
          <div class="credit-card-design bg-primary-gradient" v-if="billingSummary">
            <span class="pay-img">
              <img
                v-show="billingSummary.Billing_Card_Type == 'American Express'"
                src="../../../assets/images/american-express.png"
              />
              <img
                v-show="billingSummary.Billing_Card_Type == 'MasterCard'"
                src="../../../assets/images/mastercard.png"
              />
              <img
                v-show="billingSummary.Billing_Card_Type == 'Visa'"
                src="../../../assets/images/visa.png"
              />
            </span>
            <span class="card-no">{{ billingSummary.Billing_Card_Number }}</span>
            <div class="name-date">
              <h5 class="float-left">
                <span>Card Name</span>{{ billingSummary.Billing_Name_On_Card }}
              </h5>
              <h5 class="float-right">
                <span>Card Expiration</span>{{ billingSummary.Billing_Card_Exp }}
              </h5>
            </div>
          </div>
        </vx-card>
      </div>
    </div>

    <!-- Details of selected plan -->
    <vs-popup :title="planInformation.Name" :active.sync="billingDetailPopup" v-if="billingDetails">
      <template>
        <div class="billing-plan-detail">
          <h3>Current Pay Period</h3>
          <span class="date">{{ payPeriod }}</span>
          <div class="bill-summary mt-4">
            <h4>Plan Summary</h4>
            <p class="mt-0">
              You will be billed 
              <span v-if="planInformation.Cost">
                <span class="text-primary">${{ planInformation.Cost | decimalsTwoDigitConversion | tableRecordNumberFormatter }}</span> for
                <span class="text-primary">{{ planInformation.IncludedVisitors | tableRecordNumberFormatter }}</span> visitors identified.
              </span>
              <span v-else>
                by each identified visitor.
              </span>
            </p>
          </div>
          <div class="bill-summary">
            <h4>How to handle additional identified visitors</h4>
            <p class="mt-0" v-if="planInformation.IncludedVisitors != 0 && planInformation.IncludedVisitors != null ">
              Once we have identified your 
              <span class="text-primary">{{ planInformation.IncludedVisitors }}</span> 
              purchased visitors, how would you like to handle additional  identifications in a given pay period?
            </p>
            <div>
              <div class="vs-radio--label ml-0 mb-2 mt-1">
                <input class="mr-2" type="radio" id="allowAdditional" name="additional" value="allow" v-model="additionalVisitors" @change="isAdditionalUser('radio')">
                <label for="allowAdditional" class="cursor-pointer">Allow an additional </label>
                <v-select :options="numberList" v-model="numberLimitPerMonth" :clearable="false" class="ml-8" @input="isAdditionalUser('select')" />
                <label for="allowAdditional" class="cursor-pointer">visitors at 
                  <span class="text-primary">${{ planInformation.Rate | decimalsTwoDigitConversion | tableRecordNumberFormatter }}</span>
                  for each successful identification. 
                </label>
              </div>
              <div>
                <input class="mr-2" type="radio" id="notAllowAdditional" name="additional" value="notAllow" v-model="additionalVisitors" @change="isAdditionalUser('radio')">
                <label for="notAllowAdditional" class="cursor-pointer">Don't identify additional visitors, I don't want to spend any additional money</label>
              </div>
            </div>
          </div>
          <div class="bill-info-progress">
            <label>Current Spend: ${{ minSpendLimit | decimalsTwoDigitConversion | tableRecordNumberFormatter }} of ${{ maxSpendLimit | decimalsTwoDigitConversion | tableRecordNumberFormatter }}</label>
            <b-progress :max="visitorLimitCal" :min="planInformation.IncludedVisitors">
              <b-progress-bar 
                :value="(currentVisitors / visitorLimitCal)!='Infinity'? currentVisitors :0" 
                :label="(currentVisitors / visitorLimitCal)=='Infinity'?'0%':isNaN(currentVisitors / visitorLimitCal)?'0%':`${((currentVisitors / visitorLimitCal) * 100).toFixed(0)}%`"
              ></b-progress-bar>
            </b-progress>
            <label>Current Identified Visitors: {{ currentVisitors | tableRecordNumberFormatter }} of {{ visitorLimitCal | tableRecordNumberFormatter }}</label>
          </div>
          <div class="upgrade-billing">
            <vs-checkbox v-model="upgradePlan">Upgrade my plan starting next month</vs-checkbox>
            <v-select
              :label="'Value'"
              :options="availablePlans"
              v-model="selectedPaln"
              :clearable="false"
              class="ml-8"
            />
            <a class="ml-8 float-left my-2 btn-link" @click="selectedDetail(selectedPaln)"> View Plan Information </a>
          </div>
        </div>
      </template>
      <div class="flex justify-end modal-action-buttons w-full float-left mt-6 mb-8">
        <vs-button
          :title="LabelConstant.buttonLabelOk"
          @click="changeConsumerPlan()"
          color="primary"
          type="filled"
          class="ml-4 mr-2"
        >{{ LabelConstant.buttonLabelOk }}</vs-button>
        <vs-button
          :title="LabelConstant.buttonLabelCancel"
          color="grey"
          @click="billingDetailPopup = false"
          type="border"
          class="ml-4 grey-btn mr-2"
        >{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>

      <!-- Selected plan Detail-->
      <vs-popup title="Plan Preview" :active.sync="SelectedDetailPopup">
        <template  v-if="selectedPalnDetail">
          <div class="select-plan-detail">
            <div class="vx-row">
              <div class="vx-col xl:w-2/4 md:w-1/2 w-full lg-cus-6">
                <div class="mb-3">
                  <label>Plan Name</label>
                  <p>{{ selectedPalnDetail.Name }}</p>
                </div>
                <div class="mb-3">
                  <label>Visitors included in plan</label>
                  <p>{{ selectedPalnDetail.IncludedVisitors | tableRecordNumberFormatter }}</p>
                </div>
                <div class="mb-3">
                  <label>Capture limit</label>
                  <p>{{ selectedPalnDetail.PlanCap | tableRecordNumberFormatter }}</p>
                </div>
              </div>
              <div class="vx-col xl:w-2/4 mb-8 md:w-1/2 w-full lg-cus-6">
                <div class="mb-3">
                  <label>Monthly Fee</label>
                  <p>${{ selectedPalnDetail.Cost | tableRecordNumberFormatter }}</p>
                </div>
                <div>
                  <label>Fee per additional visitor</label>
                  <p>${{ selectedPalnDetail.Rate | decimalsTwoDigitConversion | tableRecordNumberFormatter }}</p>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="flex justify-end modal-action-buttons w-full float-left mt-6 mb-8">
          <vs-button
            :title="LabelConstant.buttonLabelOk"
            @click="SelectedDetailPopup = false"
            color="grey"
            type="border"
            class="ml-4 grey-btn"
            >{{ LabelConstant.buttonLabelOk }}</vs-button
          >
        </div>
      </vs-popup>

      <!-- Plan Changed Popup -->
      <vs-popup class="transaction-popup" :active.sync="isPlanChange">
        <template>
          <div class="vx-row mb-3">
            <div class="vx-col w-full text-center">
              <b-card-text>
                <h5>Changes were made on your plan.</h5>
              </b-card-text>
              <h6 class="text-primary py-5">Would you like to save these ?</h6>
            </div>
          </div>
        </template>
        <div class="text-center">
          <vs-button
            :title="LabelConstant.buttonLabelYes"
            color="primary"
            type="filled"
            class="ml-4 mr-2"
            @click="updateConsumerBillingPlans()"
          >{{ LabelConstant.buttonLabelYes }}</vs-button>
          <vs-button
            :title="LabelConstant.buttonLabelCancel"
            color="grey"
            @click="isPlanChange=false"
            type="border"
            class="ml-4 grey-btn mr-2"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
      </vs-popup>
    </vs-popup>

    <!-- Update Credit Card Detail -->
    <vs-popup class="add-new" title="Update Credit Card" :active.sync="updateCardActive">
      <template>
        <form data-vv-scope="updateCardForm">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mb-6">
              <vs-input
                class="w-full"
                label="Name on Card"
                v-model="billingNameOnCard"
                name="billingNameOnCard"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">{{ errors.first("updateCardForm.billingNameOnCard") }}</span>
            </div>
            <div class="vx-col md:w-1/2 w-full mb-6">
              <span class="vs-input--label">Card Type</span>
              <v-select
                v-model="billingCardType"
                :options="billingCardTypeOptions"
                :clearable="false"
              />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mb-6">
              <vs-input
                class="w-full"
                label="Credit Card Number"
                v-model="billingCardNumber"
                name="billingCardNumber"
                v-validate="'required|credit_card'"
              />
              <span class="text-danger text-sm" v-show="billingCardNumberError">{{ billingCardNumberError }}</span>
              <span class="text-danger text-sm">{{ errors.first("updateCardForm.billingCardNumber") }}</span>
              <p>Please use numbers only, no spaces or dashes.</p>
            </div>
            <div class="vx-col md:w-1/2 w-full mb-6">
              <span class="block">
                <label class="vs-input--label">Credit Card Expiration</label>
              </span>
              <v-select
                class="w-1/3 float-left mr-4"
                v-model="billingCardExpMonth"
                :options="billingCardExpMonthOptions"
                :clearable="false"
              />
              <v-select
                class="w-1/3 float-left"
                v-model="billingCardExpYear"
                :options="billingCardExpYearOptions"
                :clearable="false"
              />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mb-6">
              <vs-input
                class="w-full"
                label="Billing Address 1"
                v-model="billingAddress1"
                name="billingAddress1"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">{{ errors.first("updateCardForm.billingAddress1") }}</span>
            </div>
            <div class="vx-col md:w-1/2 w-full mb-6">
              <vs-input
                class="w-full"
                label="Billing Address 2"
                v-model="billingAddress2"
              />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mb-6">
              <vs-input
                class="w-full"
                label="City"
                v-model="billingCity"
                name="billingCity"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">{{ errors.first("updateCardForm.billingCity") }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mb-6">
              <vs-input
                class="w-full"
                label="State"
                v-model="billingState"
                name="billingState"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">{{ errors.first("updateCardForm.billingState") }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mb-6">
              <vs-input
                class="w-full"
                label="Zip"
                v-model="billingZip"
                name="billingZip"
                v-validate="'required'"
              />
              <span class="text-danger text-sm">{{ errors.first("updateCardForm.billingZip") }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full mb-6">
              <vs-input
                class="w-full"
                label="Billing Email"
                v-model="billingEmail"
                name="billingEmail"
                v-validate="'required|email'"
              />
              <span class="text-danger text-sm">{{ errors.first("updateCardForm.billingEmail") }}</span>
            </div>
          </div>
        </form>
      </template>
      <div class="flex justify-end modal-action-buttons w-full float-left mt-6 mb-8">
        <vs-button @click="updateCreditCard()" type="filled" color="primary">{{ LabelConstant.buttonLabelUpdateCreditCard }}</vs-button>
        <vs-button
          @click="cancelUpdateCreditCard()"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>

    <!-- Warning popup-->
    <vs-popup class="alert-popup billing-alert" :active.sync="visitorWarningPopup">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full text-center">
            <div class="popup-icon data-status-icon">
              <span class="material-icons text-danger">error</span>
            </div>
           
            <p>You have made a change to limit the amount of visitors that can be identified this month.  
              This change is set to a number lower than current visitors already identified this month.</p>
            <p>If you choose to continue, no additional visitors will be identified this month and you will be billed for visitors already identified.</p>
            <h6 class="text-danger">Would you like to continue with this change?</h6>
          </div>
        </div>
      </template>
      <div class="text-center mt-6 mb-8">
        <vs-button
          :title="LabelConstant.buttonLabelYes"
          color="danger"
          type="filled"
          @click="visitorWarningPopup=false,setVisitorsValues()"
          class="mr-2"
          >{{ LabelConstant.buttonLabelYes }}</vs-button
        >
        <vs-button
          :title="LabelConstant.buttonLabelNo"
          color="grey"
          type="border"
          class="ml-2 grey-btn"
          @click="visitorWarningPopup=false,resetVisitorsValues()"
          >{{ LabelConstant.buttonLabelNo }}</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import vSelect from "vue-select";
import { Validator } from "vee-validate";

const dict = {
  custom: {
    billingNameOnCard: {
      required: "A Name is required",
    },
    billingCardNumber: {
      required: "A Credit Card Number is required",
      credit_card: "Please enter valid Credit Card Number.",
    },
    billingAddress1: {
      required:"Billing address must be more than 1 and less than 300 characters",
    },
    billingCity: {
      required: "A City is required",
    },
    billingState: {
      required: "A State is required",
    },
    billingZip: {
      required: "A Zip is required",
    },
    billingEmail: {
      required: "An Email is required",
      email: "Please enter a valid email address",
    },
  },
};
Validator.localize("en", dict);

export default {
  components: {
    VxCard,
    vSelect,
  },
  data() {
    return {
      isReseller: null,
      billingInformation:null,
      billingSummary: null,
      // view detail popup
      billingDetailPopup: false,
      additionalVisitors:"allow",
      billingDetails:null,
      planInformation:null,
      allowAdditionalVisitors:false,
      budgetLimit:0,
      payPeriod:null,
      upgradePlan:false,
      upgradePlanId:null,
      currentSpend:0,
      currentVisitors: 0,
      visitorLimit: 0,
      visitorLimitCal:0,
      maxSpendLimit:0,
      isAdditionalVisitorsAdded:null,
      minSpendLimit:0,
      numberLimitPerMonth:'',
      numberList:[],
      availablePlans:null,
      selectedPaln: null,
      // update credit pop up
      updateCardActive: false,
      billingNameOnCard: "",
      billingCardType: "Visa",
      billingCardNumber: "",
      billingCardExpMonth: "1",
      billingCardExpYear: new Date().getFullYear(),
      billingAddress1: "",
      billingAddress2: "",
      billingCity: "",
      billingState: "",
      billingZip: "",
      billingEmail: "",
      billingCardTypeOptions: ["Visa", "MasterCard", "American Express"],
      billingCardExpMonthOptions: [ "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12" ],
      billingCardExpYearOptions: [],
      billingCardNumberError: null,
      // view information plan
      SelectedDetailPopup: false,
      selectedPalnDetail:null,
      // plan change pop up
      isPlanChange:false,
      visitorWarningPopup:false,
      changeType:null,
      isShowBillingV2: false,
      additionalUsersActive: false,
    };
  },
  mounted() {
    let uid = this.$route.params.id;
    let VVtoken = localStorage.getItem("VVtoken");
    let token = JSON.parse(VVtoken);
    this.isReseller = token[uid].isReseller;
    this.isShowBillingV2 = token[uid].showBillingV2;
    if (this.$route.query.userpurchase) {
      this.additionalUsersActive = true;
    }
  },
  created() {
    this.listAvailablePlans();
    this.getBillingInfo();
    this.getBillingInformation();
    this.getYears();
  },
  methods: {
    // billing information for right card
    async getBillingInfo() {
      await this.$vs.loading();
      this.axios
        .get("/ws/BillingInfo/GetBillingInfo")
        .then((response) => {
          let data = response.data;
          this.billingSummary = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // billing information for left card
    async getBillingInformation() {
      this.$vs.loading();
      await this.axios
        .get("/ws/ConsumerBilling/GetBillingInformation")
        .then((response) => {
          let data = response.data;
          this.billingInformation = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // available plans list 
    async listAvailablePlans(){
      this.$vs.loading();
      await this.axios
        .get("/ws/ConsumerBilling/ListAvailablePlans")
        .then((response) => {
          let data = response.data;
          this.availablePlans = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // Billing Information Details pop up
    async getBillingInformationDetails(){
      this.$vs.loading();
      await this.axios
        .get("/ws/ConsumerBilling/GetBillingInformationDetails")
        .then((response) => {
          let data = response.data;

          this.billingDetails = data;
          this.planInformation= this.billingDetails.PlanInformation;
          this.allowAdditionalVisitors= this.billingDetails.AllowAdditionalVisitors;
          this.budgetLimit= this.billingDetails.BudgetLimit;
          this.payPeriod= this.billingDetails.PayPeriod;
          this.currentSpend= this.billingDetails.CurrentSpend;
          this.currentVisitors= this.billingDetails.CurrentVisitors;
          this.visitorLimit= this.billingDetails.VisitorLimit;
          this.upgradePlanId= this.billingDetails.UpgradePlanId;
          this.upgradePlan= this.billingDetails.UpgradePlan;  

          // for number list ( limit par month)
          this.numberList=[];
          for(let i=0;i<=this.planInformation.PlanCap;i++){
            this.numberList.push(i);
          }
          this.numberLimitPerMonth=this.visitorLimit;
          // for choose plan
          this.selectedPaln=this.availablePlans.find((n)=> { return n.Key == this.upgradePlanId ? n.Value : null});
          // for radio buttun 
          if(this.allowAdditionalVisitors){
            this.additionalVisitors="allow";
          }else{
            this.additionalVisitors="notAllow";
          }
          this.isAdditionalVisitorsAdded=this.additionalVisitors;
          // number of the bar graph 
          if(this.currentSpend < this.planInformation.Cost){
            this.minSpendLimit=this.planInformation.Cost
          }else{
            this.minSpendLimit=this.currentSpend
          }
          this.setVisitorsValues();
          this.billingDetailPopup = true
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    setVisitorsValues(){
      setTimeout(() => {
        let addVisitors=0;
        if(this.additionalVisitors=="allow"){
          addVisitors=this.numberLimitPerMonth;
        }
        this.visitorLimitCal = addVisitors + this.planInformation.IncludedVisitors ;

        if(this.visitorLimitCal <= this.planInformation.IncludedVisitors){
          this.maxSpendLimit=this.planInformation.Cost;
        }else{
          this.maxSpendLimit = this.visitorLimitCal * this.planInformation.Rate;
        }
      },200);     
    },
    resetVisitorsValues(){
      if(this.changeType=="radio"){
        if(this.additionalVisitors=="allow"){
          this.additionalVisitors="notAllow";
        }else{
          this.additionalVisitors="allow";
        }
      }else{
        this.numberLimitPerMonth=this.visitorLimit;
      }
      this.setVisitorsValues();
    },
    // Update Credit Card Detail
    updateCreditCard() {
      this.$validator.validateAll("updateCardForm").then((result) => {
        if (result) {
          let updateCreditCardParams = {
            Billing_Card_Type: this.billingCardType,
            Billing_Card_Exp_Month: this.billingCardExpMonth,
            Billing_Card_Exp_Year: this.billingCardExpYear,
            Billing_Card_Number: this.billingCardNumber,
            Billing_Name_On_Card: this.billingNameOnCard,
            Billing_Address1: this.billingAddress1,
            Billing_Address2: this.billingAddress2,
            Billing_City: this.billingCity,
            Billing_State: this.billingState,
            Billing_Zip: this.billingZip,
            Billing_Email: this.billingEmail,
          };
          this.$vs.loading();
          this.axios
            .post("/ws/BillingInfo/UpdateCreditCard", updateCreditCardParams)
            .then(() => {
              this.billingCardNumberError = null;
              this.getBillingInfo();
              this.cancelUpdateCreditCard();
              this.$vs.notify({
                title: "Success",
                text: "Your credit card information has been updated successfully.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000,
              });
              this.$vs.loading.close();
            })
            .catch((e) => {
              this.showError(e);
              this.$vs.loading.close();
              if (e.response.data.Errors[0].Key == "Billing_Card_Number") {
                this.billingCardNumberError = e.response.data.Errors[0].Message;
              }
              this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000,
              });
            });
        }
      });
    },
    cancelUpdateCreditCard() {
      this.updateCardActive = false;
      this.billingCardType = "Visa";
      this.billingCardExpMonth = "1";
      this.billingCardExpYear = new Date().getFullYear();
      this.billingCardNumber = null;
      this.billingNameOnCard = null;
      this.billingAddress1 = null;
      this.billingAddress2 = null;
      this.billingCity = null;
      this.billingState = null;
      this.billingZip = null;
      this.billingEmail = null;
      setTimeout(() => {
        this.errors.clear("updateCardForm");
      }, 20);
    },  
    // View Plan information pop up detail
    async selectedDetail(selectedPaln){
      let planId=selectedPaln.Key
      this.$vs.loading();
      await this.axios
        .get("/ws/ConsumerBilling/GetPlanPreview?PlanId="+planId)
        .then((response) => {
          let data = response.data;
          this.selectedPalnDetail=data;
          this.SelectedDetailPopup=true;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // click on OK of billing details popup 
    changeConsumerPlan(){
      setTimeout(() => {
          if( this.numberLimitPerMonth != this.visitorLimit || 
          this.additionalVisitors != this.isAdditionalVisitorsAdded || 
          this.upgradePlan != this.billingDetails.UpgradePlan || 
          this.selectedPaln != this.availablePlans.find((n)=> { return n.Key == this.upgradePlanId ? n.Value : null}) ){
            this.isPlanChange=true;
          }else{
            this.billingDetailPopup=false;
          }
      },100);
    },
    // Update Consumer Plan and Billing
    async updateConsumerBillingPlans(){
      this.isPlanChange=false;
      this.billingDetailPopup=false;
      if(this.additionalVisitors=="allow"){
        this.allowAdditionalVisitors=true;
      }else{
        this.allowAdditionalVisitors=false;
      }
      let updatedPlan={
        VisitorLimit: this.numberLimitPerMonth,
        UpgradePlan: this.upgradePlan,
        UpgradePlanId: this.selectedPaln?this.selectedPaln.Key:this.upgradePlanId,
        AllowAdditionalVisitors: this.allowAdditionalVisitors
      }
      this.$vs.loading();
      await this.axios
        .post("/ws/ConsumerBilling/UpdateConsumerBillingPlan",updatedPlan)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // additional user value is change
    isAdditionalUser(type){
      setTimeout(() => {
        let addVisitors=0;
        if(this.additionalVisitors=="allow"){
          addVisitors=this.numberLimitPerMonth;
        }
        this.visitorLimitCal = addVisitors + this.planInformation.IncludedVisitors;
        if(this.currentVisitors > this.visitorLimitCal){
          if(type=="select"){
            if(this.additionalVisitors=="allow"){
              this.visitorWarningPopup=true
            }
          }else{
            this.visitorWarningPopup=true
          }
          this.changeType=type
        }else{
          this.setVisitorsValues();
        }
      },100);     
    },
    getYears(){
      let cyArray = [];
      let startYear = new Date().getFullYear()
      let endYear = new Date().getFullYear() + 10
      for (let index = startYear; index < endYear; index++) {
          cyArray.push(index);
      }
      this.billingCardExpYearOptions = cyArray;
    },
  },
};
</script>
